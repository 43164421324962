import React from 'react'
import { useGetFooter } from '@src/hooks'
import { FooterUi, IconType, FooterType } from '@islandsstofa/react'
import Logos from '@cmp/site/Logos'
import { FluidObject } from 'gatsby-image'

interface Footer extends FooterType {
  logoType: IconType
  en: boolean
  subfooterTitle?: string
  subfooterLogos?: { url: string; alt: string; fluid: FluidObject }[]
}

const Footer = () => {
  const d: Footer = {
    logoType: 'businessIcelandVerticalLogo',
    en: true,
    ...useGetFooter()
  }
  if (!d) {
    return null
  }

  return (
    <div>
      <FooterUi {...d} />
      <Logos title={d.subfooterTitle} logos={d.subfooterLogos} />
    </div>
  )
}

export default Footer
