import React from 'react'
import Img, { FluidObject } from 'gatsby-image'
type LogosProps = {
  title?: string
  logos?: {
    url: string
    alt: string
    fluid: FluidObject
  }[]
}

const Logos = ({
  title = 'Work in Iceland is in cooperation with',
  logos = []
}: LogosProps) => {
  console.log(logos)

  if (logos.length === 0) return null
  return (
    <div className='footer-logos pb-5 pt-2'>
      <div className='container'>
        <h4 className='mb-2'>{title}</h4>
        <div className='d-flex flex-lg-row flex-column flex-wrap footer-logos__icons'>
          {logos.map((logo, key) => (
            <Img
              key={key}
              className='mr-4 mb-3 col-lg-4 footer-logos__icons--item'
              fluid={logo.fluid}
              alt={logo.alt ?? ''}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Logos
