import React, { useContext, useEffect, useState } from 'react'
import { Container, Col, Sticky } from '@kogk/common'
import { Link } from 'gatsby'
import { useGlobalData } from '@src/data/global-data'
import cn from 'classnames'
import { MobileMenuContext } from '@root/src/context/mobileMenuContext'
import {
  Icon,
  MenuItemType,
  Burger
} from '@islandsstofa/react'
import NavItem from '@cmp/site/NavItem'
import useSetBg from './useSetBg'
import { useSession } from '@root/src/context/sessionContext'

const Logo = () => {
  const { close: closeMobileMenu } = useContext(MobileMenuContext)
  return (
    <Link to='/' onClick={() => closeMobileMenu()} aria-label='home'>
      <Icon containerClass='d-none d-lg-block logo' type='workInIcelandLogo' />
      <Icon containerClass='d-block d-lg-none logo' type='workInIcelandLogo' />
    </Link>
  )
}

const Navigation: React.FC<{
  items: MenuItemType[]
  containerClass: string
}> = ({ items, containerClass }) => {
  return (
    <div className={cn(containerClass)}>
      {items
        .filter(item => item)
        .map((item, idx) => (
          <NavItem key={idx} item={item} />
        ))}
    </div>
  )
}

const LanguageAndSearch = () => {
  return (
    <Link to='/search-our-sites'>
      <Icon type='search'></Icon>
    </Link>
  )
}

const NavBar = () => {
  const { mainMenu } = useGlobalData()
  const { isOpen, close, open } = useContext(MobileMenuContext)
  const transparent = useSetBg()
  const { keys } = useSession()
  const bgStyle = keys.find(k => k.id === 'bg-style')
  const [loaded, setLoaded] = useState(false)

  useEffect(() => setLoaded(true), [])
  return (
    <Sticky initialHeight={88}>
      <nav
        className={cn('navbar', {
          'navbar--mobile-dropdown-open': isOpen,
          'navbar--transparent':
            transparent && !isOpen && bgStyle && bgStyle.param === 'black-font',
          'navbar--transparent navbar--transparent--white-font':
            transparent && !isOpen && bgStyle && bgStyle.param === 'white-font',
          'navbar--hide': !loaded
        })}
      >
        <Container>
          <div className='d-flex navbar__item-wrap align-items-center justify-content-between'>
            <div>
              <Logo />
            </div>
            <>
              <Navigation
                containerClass='w-100 justify-content-center d-none d-lg-flex'
                items={mainMenu}
              />
              <Col className='d-none d-lg-flex justify-content-end' col={1}>
                <LanguageAndSearch />
              </Col>
            </>

            <div className='d-flex d-lg-none justify-content-end'>
              <div
                className={cn('mr-3 mt-n1', {
                  'd-none': isOpen
                })}
              >
                <LanguageAndSearch />
              </div>
              <Burger isOpen={isOpen} open={open} close={close} />
            </div>
          </div>
        </Container>
      </nav>
    </Sticky>
  )
}

export default NavBar
