import React, { useContext } from 'react'
import { useLockBodyScroll } from '@src/hooks'
import cn from 'classnames'
import {
  IconButton,
  ModalTemplateType,
  ModalContext,
  StaffModalTemplate as Staff
} from '@islandsstofa/react'

// TEMPLATES
import { GreentechPartner, ImageGallery, Search, Video } from './templates'

const ModalContent: React.FC<{
  templateType: ModalTemplateType
  content: any
}> = ({ templateType, content }) => {
  const templates: { [key: string]: React.ElementType } = {
    Staff,
    GreentechPartner,
    ImageGallery,
    Search,
    Video
  }

  useLockBodyScroll()

  const Cmp = templates[templateType]
  if (!Cmp) {
    console.log(`Modal template missing of type: ${templateType}`)
    return null
  }
  return <Cmp content={content} />
}

export default () => {
  const {
    contentArray,
    idx,
    isOpen,
    containerClass,
    closeModal,
    nextContent,
    prevContent,
    templateType
  } = useContext(ModalContext)

  const thisIsMobile = 'd-flex d-md-none'
  const thisIsDesktop = 'd-none d-md-flex'

  const ControlPanel = () => {
    const Arrows = ({ containerClass = '' }) => {
      return (
        <>
          {contentArray.length > 1 && (
            <div className={cn('d-flex', containerClass)}>
              <IconButton
                type='modalArrow'
                className='mr-2'
                style={{ transform: `rotate(180deg)` }}
                onClick={() => prevContent()}
              />
              <IconButton type='modalArrow' onClick={() => nextContent()} />
            </div>
          )}
        </>
      )
    }

    return (
      <>
        {/* desktop */}
        <div
          className={cn(
            thisIsDesktop,
            'modal__option-wrap justify-content-end align-items-center col-4 pl-4'
          )}
        >
          <Arrows />
          <IconButton
            className='ml-4'
            onClick={() => closeModal()}
            type='close'
          />
        </div>

        {/* mobile */}
        <div className={cn(thisIsMobile)}>
          <div className='modal__option-wrap'>
            <span />
            <IconButton onClick={() => closeModal()} type='close' />
          </div>
          <div
            className={cn('modal__arrow-wrap-mobile mt-5', {
              'd-none': contentArray.length <= 1
            })}
          >
            <Arrows containerClass='modal__arrow-wrap-mobile--inner justify-content-between w-100 px-0 position-absolute' />
          </div>
        </div>
      </>
    )
  }

  if (isOpen) {
    return (
      <div className='modal__wrap'>
        <div className={cn('modal', containerClass)}>
          <ControlPanel />
          <ModalContent
            content={contentArray[idx]}
            templateType={templateType}
          />
        </div>
        <div onClick={() => closeModal()} className='modal__click-zone' />
      </div>
    )
  } else return null
}
