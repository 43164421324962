import React, { useContext, useMemo } from 'react'
import {
  LanguageProvider,
  langCompare,
  useLanguage
} from '@kogk/gatsby-plugin-i18n'
import { graphql, useStaticQuery } from 'gatsby'
import '@src/data/prismic-fragments/menu'

const Ctx = React.createContext({
  altUrls: [],
  mainMenu: [],
  langMenu: []
})

export const GlobalDataProvider = ({ language, altUrls = [], children }) => {
  const results = useStaticQuery(query)
  const { availableLanguages } = useLanguage()

  const { translations, ...data } = useMemo(
    () => processData({ results, language, altUrls, availableLanguages }),
    [results, language, altUrls, availableLanguages]
  )

  return (
    <LanguageProvider
      data={{
        language,
        translations
      }}
    >
      <Ctx.Provider
        value={{
          ...data
        }}
      >
        {children}
      </Ctx.Provider>
    </LanguageProvider>
  )
}

export const useGlobalData = () => {
  return useContext(Ctx)
}

export const query = graphql`
  query {
    allPrismicMenu {
      nodes {
        ...fragmentPrismicMenu
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const makeMenu = (results, id) => {
  const menu = results.find(m => m.prismicId === id)

  if (!menu) {
    throw new Error(`cant find menu with id ${id}`)
  }

  const {
    data: { nav_links: items }
  } = menu

  return items
    .filter(item => !item.link.isBroken)
    .map(({ link_name: title, link, sub_menu: subMenu }) => {
      return {
        title: title.text,
        url: link.document.url,
        subMenu: extractSubMenu(subMenu, results),
        megaMenu: extractMegaMenu(subMenu)
      }
    })
}

const extractMegaMenu = subMenu => {
  const isMegaMenu =
    subMenu.id &&
    subMenu.type === 'megamenu' &&
    subMenu.document &&
    subMenu.document.data

  return isMegaMenu ? subMenu.document.data.body : []
}

const extractSubMenu = (subMenu, results) => {
  const isNormalMenu = subMenu.id && subMenu.type === 'menu'

  return isNormalMenu ? makeMenu(results, subMenu.id) : []
}

const processData = ({ results, language, altUrls, availableLanguages }) => {
  const {
    allPrismicMenu: { nodes: menuNodes },
    site: {
      siteMetadata: { siteUrl }
    }
  } = results

  const mainMenu = menuNodes
    .filter(menu => langCompare(menu.lang, language))
    .find(menu => menu.data.menu_name === 'mainmenu')

  if (!mainMenu) {
    console.log(menuNodes, language)
    throw new Error('mainmenu not found')
  }

  return {
    translations: [],
    mainMenu: makeMenu(menuNodes, mainMenu.prismicId),
    langMenu: makeLangMenu(language, availableLanguages, altUrls),
    siteUrl
  }
}

const makeLangMenu = (language, availableLanguages, altUrls) => {
  return [
    {
      title: language,
      megaMenu: null,
      subMenu: availableLanguages.map(l => {
        const found = altUrls.find(entry => langCompare(entry.lang, l))

        return {
          title: l,
          // todo: find some way of figuring out the frontpage url for this lang
          url: found ? found.url : '/'
        }
      })
    }
  ]
}

/**
 * takes a list of translations from the prismic query and turns it into a data
 * type compatible with our strings in the i18n plugin
 * @param {array} gatsby node list
 */
const makeTranslations = nodes => {
  return nodes.reduce((acc, node) => {
    const {
      lang,
    } = node

    const langKey = lang.substring(0, 2)
    return {
      ...acc,
      [langKey]: {
        ...(acc[langKey] || {}),
      }
    }
  }, {})
}
