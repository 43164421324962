import { useState, useEffect } from 'react'
import { useLocation } from '@reach/router'

const useSetBg = () => {
  const [transparent, setTransparent] = useState(true)

  const { pathname } = useLocation()

  useEffect(() => {
    // remove this later
    if (
      pathname === '/' ||
      pathname === '/testing-grounds' ||
      pathname === '/testing-grounds/'
    ) {
      const update = (e: any) => {
        if (e.target) {
          const scrollPos = e.target.scrollingElement.scrollTop
          if (scrollPos > window.innerHeight * 0.8) {
            setTransparent(false)
          } else setTransparent(true)
        }
      }

      document.addEventListener('scroll', update)

      return () => document.removeEventListener('scroll', update)
    } else {
      setTransparent(false)
    }
  }, [])

  return transparent
}

export default useSetBg
